.fc-day-top {
  padding-bottom: 15px !important;
}

td {
  padding: 10px;
}

.fc-content::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
