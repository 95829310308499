.react-datepicker { 
    font-size: 1rem !important;
} 
.react-datepicker-popper {
    z-index: 9999 !important;
}
.react-datepicker :focus {
    outline: none !important;
}
.react-datepicker__current-month { 
    font-size: 1.2rem !important; 
    padding: 12px 0 12px 0 !important;
} 
.react-datepicker__header { 
    padding: 6px 6px 0 6px !important; 
    background-color: #fff !important;
    border-bottom: none !important;
} 
.react-datepicker__navigation { 
    top: 25px !important; 
    margin: 0 20px !important;
} 
.react-datepicker__day-names {
    border-bottom: 1px solid #cccccc !important;
    padding-bottom: 6px !important;
}
.react-datepicker__day-name {
    margin: 0.3rem 0.4rem !important; 
    font-weight: bold !important;
    color: #696969 !important;
}
.react-datepicker__day {
    margin: 0.3rem 0.4rem !important; 
    color: #9A9A9A !important;
}
.react-datepicker__day--today {
    color: #000000 !important;
}
.saturday, .react-datepicker__day-name:nth-child(7) { 
    color: rgb(37, 100, 218) !important; 
} 
.sunday, .react-datepicker__day-name:nth-child(1) { 
    color: rgb(247, 105, 105) !important; 
} 
.react-datepicker__day--disabled { 
    color: #cccccc !important; 
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem !important;
  background-color: #1A73E9 !important;
  color: #fff !important;
}
.react-datepicker__triangle {
    display: none !important;
}