.header {
}

table {
  width: 100%;
  border-collapse: collapse;
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;
}

.ReactVirtualized__Table__headerRow {
  background: rgb(233, 236, 239);
  border-top: 2px solid rgb(100, 100, 100);
  border-bottom: 1px solid rgb(173, 181, 189);
  text-align: center;
}
.ReactVirtualized__Table__headerColumn header {
  height: 100px;
}
.table_wrapper ::-webkit-scrollbar {
  border-radius: 10px;
  width: 11px;
}

.table_wrapper ::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  border-radius: 6px;
  border: 3px solid #cfd8dc;
}
.table_wrapper ::-webkit-scrollbar-track {
  background: #cfd8dc;
}
@media screen and (max-width: 1600px) {
  .ReactVirtualized__Table__rowColumn {
    font-size: 0.9rem;
  }
}

.ReactVirtualized__Table__row {
  cursor: pointer;
  border-bottom: 0.75px solid rgba(204, 204, 204, 0.5);
}
.ReactVirtualized__Table__row:hover {
  background: rgba(204, 204, 204, 0.5);
}

.action_buttons {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  color: #90a4ae;
  font-weight: bold;
  border: none;
  outline: none;
  font-size: 0.875rem;
  cursor: pointer;
}
.action_buttons:hover {
  background: #f8f9fa;
  color: #15aabf;
}

.ReactVirtualized__Table__rowColumn {
  text-align: center;
}
