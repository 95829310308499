.ReactVirtualized__List ::-webkit-scrollbar {
  border-radius: 10px;
  width: 11px;
}

.ReactVirtualized__List ::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  border-radius: 6px;
  border: 3px solid #cfd8dc;
}
.ReactVirtualized__List ::-webkit-scrollbar-track {
  background: #cfd8dc;
}

.ReactVirtualized__List {
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;
}

.search_list::-webkit-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;
}
