.template1_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background-color: #ee4e2c;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template1_sm-text1 {
    font-size: 3.8rem;
    color: #fff;
    padding: 10px 10px;
    border-top: 4px solid #fff;
    border-bottom: 4px solid #fff;
    font-weight: bold;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    margin-bottom: 30px;
}
.template1_sm-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    font-size: 2.7rem;
}
.template1_sm-text3 {
    font-size: .9rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #fff;
    font-size: 2rem;
}
.template1_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background-color: #ee4e2c;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template1_lg-text1 {
    font-size: 3.8rem;
    color: #fff;
    padding: 10px 10px;
    border-top: 4px solid #fff;
    border-bottom: 4px solid #fff;
    font-weight: bold;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    margin-bottom: 30px;
}
.template1_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    font-size: 2rem;
}
.template1_lg-text3 {
    font-size: .9rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #fff;
    font-size: 1.7rem;
}
.template2_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template2_sm-text1 {
    font-size: 2rem;
    color: #fec013;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
}
.template2_sm-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 3.8rem;
    padding: 15px
}
.template2_sm-text3 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-size: 2rem;
}
.template2_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template2_lg-text1 {
    font-size: 2.2rem;
    color: #fec013;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
}
.template2_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 3.8rem;
    padding: 15px
}
.template2_lg-text3 {
    font-size: 2.2rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    padding: 30px;
    color: #fff;
}
.template3_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background: url("../../resources/images/template/background/template3bg.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 40px;
}
.template3_sm-text1 {
    font-size: 2rem;
    color: #fff;
    background-color: #b8bcbf;
    white-space: pre-line;
    word-break: keep-all;
    padding: 5px 8px 3px 8px;
    text-align: left;
}
.template3_sm-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: left;
    font-weight: bold;
    color: #000;
    font-size: 3.5rem;
    padding: 10px 0;
}
.template3_sm-text3 {
    font-size: 1rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: left;
    color: #000;
    padding-left: 5px;
    padding-top: 15px;
    font-size: 2rem;
}
.template3_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background: url("../../resources/images/template/background/template3_lg_bg.jpg");
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 40px;
}
.template3_lg-text1 {
    font-size: 2rem;
    color: #fff;
    background-color: #b8bcbf;
    white-space: pre-line;
    word-break: keep-all;
    padding: 5px 8px 3px 8px;
    text-align: left;
}
.template3_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: left;
    font-weight: bold;
    color: #000;
    font-size: 3.7rem;
    padding: 30px 0 60px 0;
}
.template3_lg-text3 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: left;
    color: #000;
    padding-left: 5px;
    font-size: 2rem;
}
.template4_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background-color: #1a73e9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template4_sm-text1 {
    font-size: 2.2rem;
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
}
.template4_sm-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 3.8rem;
    padding: 15px
}
.template4_sm-text3 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    padding: 12px 30px 10px 30px;
    margin-top: 20px;
    font-weight: bold;
    border-radius: 20rem;
    color: #1a73e9;
    background-color: #fff;
    font-size: 2rem;
}
.template4_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background-color: #1a73e9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template4_lg-text1 {
    font-size: 2.2rem;
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
}
.template4_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 3.8rem;
    padding: 25px
}
.template4_lg-text3 {
    font-size: 2.2rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    padding: 12px 30px 40px 30px;
    margin-top: 20px;
    border-radius: 20rem;
    color: #fff;
}
.template4_lg-text4 {
    font-size: 1.6rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    padding: 12px 30px 10px 30px;
    margin-top: 20px;
    border-radius: 20rem;
    color: #1a73e9;
    background-color: #fff;
}
.template5_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background: url("../../resources/images/template/background/template5bg.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template5_sm-text1 {
    font-size: 2rem;
    color: #fff;
    background-color: #969696;
    white-space: pre-line;
    word-break: keep-all;
    padding: 8px 20px 5px 20px;
    border-radius: 20rem;
    text-align: center;
}
.template5_sm-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 3.5rem;
    padding: 20px 0;
}
.template5_sm-text3 {
    font-size: 2.2rem;
    white-space: pre-line;
    padding-top: 15px;
    word-break: keep-all;
    text-align: center;
    color: #fff;
}
.template5_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background: url("../../resources/images/template/background/template5bg.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template5_lg-text1 {
    font-size: 2rem;
    color: #fff;
    background-color: #969696;
    white-space: pre-line;
    word-break: keep-all;
    padding: 8px 20px 5px 20px;
    border-radius: 20rem;
    text-align: center;
}
.template5_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 3.5rem;
    padding: 30px 0 10px 0;
}
.template5_lg-text3 {
    font-size: 2rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #fff;
    padding: 20px 10px 30px 10px;
}
.template5_lg-text4 {
    font-size: 1.65rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #fff;
    padding: 20px;
}
.template6_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background: url("../../resources/images/template/background/template6bg.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template6_sm-text1 {
    font-size: 2rem;
    color: #fe645a;
    background-color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    padding: 8px 20px 5px 20px;
    border-radius: 20rem;
    text-align: center;
}
.template6_sm-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 3.5rem;
    padding: 20px 0;
    margin-bottom: 30px;
}
.template6_sm-text3 {
    position: absolute;
    bottom: 15px;
    font-size: 1.7rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #fe645a;
}
.template6_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background: url("../../resources/images/template/background/template6_lg_bg.jpg");
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template6_lg-text1 {
    font-size: 2rem;
    color: #fe645a;
    background-color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    padding: 8px 20px 5px 20px;
    border-radius: 20rem;
    text-align: center;
}
.template6_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 3.5rem;
    padding: 30px 0 50px 0;
}
.template6_lg-text3 {
    font-size: 2rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #fff;
    margin-bottom: 40px;
}
.template6_lg-text4 {
    position: absolute;
    bottom: 20px;
    font-size: 1.7rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #fe645a;
}
.template7_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background: url("../../resources/images/template/background/template7bg.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.template7_sm-text1 {
    font-size: 2.1rem;
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    padding: 5px 0;
    text-align: left;
    margin-left: 45%;
}
.template7_sm-text2 {
    white-space: pre-line;
    font-family: 'Recipekorea';
    word-break: keep-all;
    text-align: left;
    color: #350d18;
    font-size: 3.2rem;
    padding: 10px 0;
    margin-left: 45%;
}
.template7_sm-text3 {
    font-size: 1.8rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: left;
    padding: 5px 0;
    color: #350d18;
    margin-left: 45%;
}
.template7_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background: url("../../resources/images/template/background/template7_lg_bg.jpg");
    background-size: contain;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.template7_lg-text1 {
    font-size: 3.2rem;
    font-family: 'Recipekorea';
    color: #350d18;
    white-space: pre-line;
    word-break: keep-all;
    padding: 8px 20px 5px 20px;
    text-align: center;
    margin-top: 150px;
    margin-bottom: 40px;
}
.template7_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    line-height: 170%;
    color: #350d18;
    font-size: 2rem;
    padding: 30px 50px;
    font-family: 'NanumBarunGothic';
    font-weight: 700;
    background-color: #fff;
    border-radius: .8rem;
}
.template7_lg-text3 {
    position: absolute;
    padding: 12px 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    font-size: 1.7rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #b1d2d7;
}
.template8_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background: url("../../resources/images/template/background/template8bg.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template8_sm-text1 {
    font-size: 2.8rem;
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    padding: 5px 0;
    text-align: center;
    font-family: 'SDSamliphopangche_Outline';
}
.template8_sm-text2 {
    white-space: pre-line;
    font-family: 'SDSamliphopangche_Outline';
    word-break: keep-all;
    text-align: center;
    color: #ffceec;
    font-size: 4.2rem;
    padding: 15px 0;
}
.template8_sm-text3 {
    margin-top: 20px;
    font-size: 2rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    font-family: 'paybooc-Bold';
    padding: 5px 0;
    color: #ffffff;
}
.template8_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background: url("../../resources/images/template/background/template8_lg_bg.jpg");
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template8_lg-text1 {
    font-size: 2.8rem;
    font-family: 'SDSamliphopangche_Outline';
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    padding: 10px 10px;
    text-align: center;
}
.template8_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #ffceec;
    font-size: 4rem;
    padding: 10px 10px;
    font-family: 'SDSamliphopangche_Outline';
}
.template8_lg-text3 {
    padding: 40px 50px;
    font-size: 2rem;
    white-space: pre-line;
    word-break: keep-all;
    font-family: 'paybooc-Bold';
    text-align: center;
    border: 1px solid #fff;
    color: #fff;
    margin-top: 30px;
}
.template8_lg-text4 {
    padding: 10px 10px;
    font-size: 2rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #fff;
    font-family: 'paybooc-Bold';
    margin-top: 40px;
}
.template9_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background: url("../../resources/images/template/background/template9bg.jpg");
    background-size: cover;
    display: flex;
    padding-right: 20px;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}
.template9_sm-text1 {
    font-size: 2.7rem;
    color: #ae9d7d;
    white-space: pre-line;
    word-break: keep-all;
    padding: 5px 0;
    text-align: right;
    font-family: 'Cafe24Ohsquare';
}
.template9_sm-text2 {
    white-space: pre-line;
    font-family: 'Cafe24Ohsquare';
    word-break: keep-all;
    text-align: right;
    color: #fff;
    font-size: 3.8rem;
    padding: 15px 0;
}
.template9_sm-text3 {
    margin-top: 10px;
    font-size: 2rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: right;
    font-family: 'paybooc-Bold';
    padding: 5px 0;
    color: #ffffff;
}
.template9_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background: url("../../resources/images/template/background/template9_lg_bg.jpg");
    background-size: contain;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 25px;
    padding-top: 350px;
}
.template9_lg-text1 {
    font-size: 2.5rem;
    color: #ae9d7d;
    white-space: pre-line;
    word-break: keep-all;
    padding: 5px 10px;
    font-family: 'Cafe24Ohsquare';
    text-align: left;
}
.template9_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: left;
    color: #fff;
    font-size: 3.5rem;
    font-family: 'Cafe24Ohsquare';
    padding: 10px 10px;
}
.template9_lg-text3 {
    padding: 10px 10px;
    font-size: 1.7rem;
    white-space: pre-line;
    word-break: keep-all;
    font-family: 'paybooc-Bold';
    text-align: left;
    color: #fff;
}
.template9_lg-text4 {
    padding: 10px 10px;
    font-size: 1.8rem;
    white-space: pre-line;
    line-height: 170%;
    word-break: keep-all;
    font-family: 'paybooc-Bold';
    text-align: left;
    color: #fff;
}
.template10_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background: url("../../resources/images/template/background/template10bg.jpg");
    background-size: cover;
    display: flex;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 41%;
}
.template10_sm-text1 {
    font-size: 2rem;
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    background-color: #a46667;
    padding: 5px 15px;
    text-align: right;
    font-family: 'Cafe24Oneprettynight';
}
.template10_sm-text2 {
    white-space: pre-line;
    font-family: 'Cafe24Oneprettynight';
    word-break: keep-all;
    text-align: right;
    color: #a46667;
    font-size: 3.4rem;
    padding: 15px 0;
}
.template10_sm-text3 {
    font-size: 1.8rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: right;
    font-family: 'Cafe24Oneprettynight';
    padding: 5px 0;
    color: #9f8e83;
}
.template10_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background: url("../../resources/images/template/background/template10_lg_bg.jpg");
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 28%;
}
.template10_lg-text1 {
    font-size: 2.2rem;
    color: #fff;
    background-color: #a46667;
    white-space: pre-line;
    word-break: keep-all;
    padding: 5px 15px;
    font-family: 'Cafe24Oneprettynight';
    text-align: left;
}
.template10_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: left;
    color: #a46667;
    font-size: 3.7rem;
    font-family: 'Cafe24Oneprettynight';
    padding: 20px 10px;
}
.template10_lg-text3 {
    padding: 15px 30px;
    border-radius: .8rem;
    font-size: 2.1rem;
    white-space: pre-line;
    line-height: 180%;
    word-break: keep-all;
    font-family: 'Cafe24Oneprettynight';
    text-align: center;
    color: #9f8e83;
    background-color: rgba(255, 255, 255, .5);
}
.template11_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background: url("../../resources/images/template/background/template11bg.jpg");
    background-size: cover;
    display: flex;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 41%;
}
.template11_sm-text1 {
    font-size: 1.8rem;
    color: #1a73e9;
    white-space: pre-line;
    word-break: keep-all;
    background-color: #fff;
    padding: 5px 15px;
    text-align: center;
    font-family: 'paybooc-Bold';
}
.template11_sm-text2 {
    white-space: pre-line;
    font-family: 'paybooc-Bold';
    word-break: keep-all;
    text-align: center;
    color: #ffcd47;
    font-size: 3.7rem;
    padding: 15px 0;
}
.template11_sm-text3 {
    font-size: 1.5rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: right;
    font-family: 'paybooc-Bold';
    padding: 5px 0;
    color: #fff;
}
.template11_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background: url("../../resources/images/template/background/template11_lg_bg.jpg");
    background-size: contain;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.template11_lg-text1 {
    font-size: 3rem;
    position: absolute;
    top: 75px;
    width: 100%;
    text-align: center;
    color: #1a73e9;
    background-color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    padding: 8px 15px;
    font-family: 'paybooc-Bold';;
}
.template11_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: left;
    color: #fff;
    font-size: 1.55rem;
    line-height: 220%;
    font-family: 'paybooc-Bold';
    padding: 20px 10px;
    margin-left: 38%;
    margin-top: 30%
}
.template11_lg-text3 {
    font-size: 1.3rem;
    white-space: pre-line;
    line-height: 150%;
    word-break: keep-all;
    padding: 20px 10px;
    text-align: left;
    color: #fff;
    margin-left: 38%;
}
.template12_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background: url("../../resources/images/template/background/template12bg.jpg");
    background-size: cover;
    display: flex;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 32%;
}
.template12_sm-text1 {
    font-size: 2.2rem;
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    padding: 5px 5px;
    text-align: center;
    font-family: 'twayair';
}
.template12_sm-text2 {
    white-space: pre-line;
    font-family: 'twayair';
    word-break: keep-all;
    text-align: center;
    color: #67534b;
    font-size: 3.2rem;
    padding: 15px 5px;
    margin-bottom: 20px;
}
.template12_sm-text3 {
    font-size: 1.8rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: right;
    font-family: 'twayair';
    padding: 5px 5px;
    color: #fff;
}
.template12_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background: url("../../resources/images/template/background/template12_lg_bg.jpg");
    background-size: contain;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
}
.template12_lg-text1 {
    font-size: 2.5rem;
    text-align: center;
    line-height: 140%;
    color: #67534b;
    white-space: pre-line;
    word-break: keep-all;
    margin-bottom: 20px;
    padding: 8px 15px;
    font-family: 'twayair';;
}
.template12_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    color: #fff;
    font-size: 2.1rem;
    line-height: 150%;
    font-family: 'twayair';
    margin-bottom: 20px;
    padding: 8px 15px;
}
.template12_lg-text3 {
    font-size: 1.7rem;
    white-space: pre-line;
    line-height: 140%;
    word-break: keep-all;
    padding: 8px 15px;
    text-align: center;
    color: #fff;
    background-color: rgb(182, 101, 101);
    font-family: 'twayair';
}
.template13_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background: url("../../resources/images/template/background/template13bg.jpg");
    background-size: cover;
    display: flex;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template13_sm-text1 {
    font-size: 3rem;
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    padding: 5px 5px;
    text-align: center;
    font-family: 'Cafe24Oneprettynight';
}
.template13_sm-text2 {
    white-space: pre-line;
    font-family: 'Cafe24Oneprettynight';
    word-break: keep-all;
    text-align: center;
    color: #fff;
    font-size: 5rem;
    padding: 5px 5px;
}
.template13_sm-text3 {
    font-size: 2rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: right;
    margin-top: 20px;
    font-family: 'Cafe24Oneprettynight';
    padding: 5px 5px;
    color: #fff;
}
.template13_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background: url("../../resources/images/template/background/template13_lg_bg.jpg");
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    padding-right: 30px;
}
.template13_lg-text1 {
    font-size: 3rem;
    text-align: right;
    line-height: 110%;
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    padding: 8px 15px;
    font-family: 'Cafe24Oneprettynight';;
}
.template13_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: right;
    color: #fff;
    font-size: 7.7rem;
    line-height: 110%;
    font-family: 'Cafe24Oneprettynight';
    padding: 8px 15px;
}
.template13_lg-text3 {
    font-size: 2.2rem;
    white-space: pre-line;
    margin-top: 20px;
    line-height: 130%;
    word-break: keep-all;
    padding: 8px 15px;
    text-align: right;
    color: #fff;
    font-family: 'Cafe24Oneprettynight';
}
.template14_sm-background {
    position: relative;
    width: 720px;
    height: 360px;
    background: url("../../resources/images/template/background/template14bg.jpg");
    background-size: cover;
    display: flex;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.template14_sm-text1 {
    font-size: 2.5rem;
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    padding: 5px 5px;
    text-align: center;
    font-family: 'KOTRA_BOLD-Bold';
}
.template14_sm-text2 {
    white-space: pre-line;
    font-family: 'KOTRA_BOLD-Bold';
    word-break: keep-all;
    text-align: center;
    color: #fff;
    font-size: 4.3rem;
    padding: 5px 5px;
}
.template14_sm-text3 {
    font-size: 1.7rem;
    white-space: pre-line;
    word-break: keep-all;
    text-align: right;
    margin-top: 15px;
    font-family: 'KOTRA_BOLD-Bold';
    padding: 5px 5px;
    color: #fff;
}
.template14_lg-background {
    position: relative;
    width: 720px;
    height: 720px;
    background: url("../../resources/images/template/background/template14_lg_bg.jpg");
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 20px;
}
.template14_lg-text1 {
    font-size: 2.2rem;
    text-align: left;
    line-height: 130%;
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;
    padding: 8px 15px;
    font-family: 'KOTRA_BOLD-Bold';;
}
.template14_lg-text2 {
    white-space: pre-line;
    word-break: keep-all;
    text-align: left;
    color: #fff;
    font-size: 5.2rem;
    line-height: 140%;
    font-family: 'KOTRA_BOLD-Bold';
    padding: 8px 15px;
}
.template14_lg-text3 {
    font-size: 2rem;
    white-space: pre-line;
    margin-top: 20px;
    line-height: 160%;
    word-break: keep-all;
    padding: 8px 15px;
    text-align: left;
    color: #fff;
    font-family: 'KOTRA_BOLD-Bold';
}

@font-face {
    font-family: 'KOTRA_BOLD-Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10-21@1.1/KOTRA_BOLD-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'twayair';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_tway@1.0/twayair.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cafe24Oneprettynight';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Cafe24Oneprettynight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cafe24Ohsquare';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/Cafe24Ohsquare.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'paybooc-Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/paybooc-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SDSamliphopangche_Outline';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts-20-12@1.0/SDSamliphopangche_Outline.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Recipekorea';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/Recipekorea.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NanumBarunGothic';
    font-style: normal;
    font-weight: 400;
    src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot');
    src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot?#iefix') format('embedded-opentype'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff') format('woff'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.ttf') format('truetype');
   }
   
   @font-face {
    font-family: 'NanumBarunGothic';
    font-style: normal;
    font-weight: 700;
    src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.eot');
    src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.eot?#iefix') format('embedded-opentype'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.woff') format('woff'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.ttf') format('truetype')
   }
   
   @font-face {
    font-family: 'NanumBarunGothic';
    font-style: normal;
    font-weight: 300;
    src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.eot');
    src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.eot?#iefix') format('embedded-opentype'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.woff') format('woff'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.ttf') format('truetype');
   }
   
   .nanumbarungothic * {
    font-family: 'NanumBarunGothic', sans-serif;
   }